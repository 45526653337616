/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {LayoutModule} from '@angular/cdk/layout';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfigService} from './core/service/config.service'; // for config service

// import {AppModule} from '../app/app.component';

import {FormsModule} from '@angular/forms';
import {MaterialModule} from 'projects/material/src/public_api';
import {AppComponent} from './app.component';
import {FormEntryModule} from './form-entry/form-entry.module';
import {GlobalAngular2ErrorHandlerComponent} from './global-angular2-error-handler/global-angular2-error-handler.component';
import {GlobalErrorHandler} from './global-angular2-error-handler/global-angular2-error-handler';
import {DataExportModule} from './data-export/data-export.module';
import {KansoCommonModule} from './kanso-common/kanso-common.module';

import {ChartsModule} from 'ng2-charts';
import {VouchersModule} from './vouchers/vouchers.module';
import {SharedModule} from './shared/shared.module';
import {OccupancyModule} from './custom/occupancy/occupancy.module';
import {VouchersAddEditrftaComponent} from './vouchers/vouchers-addeditrfta/vouchers-addeditrfta.component';
import {RouterModule, Routes} from '@angular/router';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {VouchersAddEditComponent} from './vouchers/vouchers-addedit/vouchers-addedit.component';
import {VouchersOverviewComponent} from './vouchers/vouchers-overview/vouchers-overview.component';
import {VouchersIssueWizardComponent} from './vouchers/vouchers-issue-wizard/vouchers-issue-wizard.component';
import {HttpLink} from 'apollo-angular/http';
import {ApolloLink, from, InMemoryCache} from '@apollo/client/core';
import {CoreModule} from './core/core.module';
import {ParameterStoreService} from './core/core.module';
import {SitePropertiesService} from './shared/services';

import {CustomModule} from './custom/custom.module';
import {GeneralLedgerComponent} from './custom/setup/accounting-setup/general-ledger/general-ledger.component';
import {GeneralLedgerEditComponent} from './custom/setup/accounting-setup/general-ledger/general-ledger-edit/general-ledger-edit.component';
import {HousingAuthoritySetupComponent} from './custom/setup/housing-authority-setup/housing-authority-setup.component';
import {EditOfficeLocationComponent} from './custom/setup/housing-authority-setup/edit-office-location/edit-office-location.component';
import {ProgramSetupComponent} from './custom/setup/program-setup/program-setup.component';
import {ProgramSetupListComponent} from './custom/setup/program-setup-list/program-setup-list.component';
import {ProgramRentCalcComponent} from './custom/setup/program-setup/program-rent-calc/program-rent-calc.component';
import {AccountActivityRuleComponent} from './custom/setup/account-activity-rule/account-activity-rule.component';
import {ProjectSetupComponent} from './custom/setup/project-setup/project-setup.component';
import {ProjectSetupListComponent} from './custom/setup/project-setup-list/project-setup-list.component';
import {SubledgerMappingComponent} from './custom/setup/accounting-setup/subledger-mapping/subledger-mapping.component';
import {SubledgerMappingEditComponent} from './custom/setup/accounting-setup/subledger-mapping/subledger-mapping-edit/subledger-mapping-edit.component';
import {SubledgerMappingEditFullComponent} from './custom/setup/accounting-setup/subledger-mapping/subledger-mapping-edit-full/subledger-mapping-edit-full.component';
import {SubCategoriesComponent} from './custom/setup/accounting-setup/subledger-mapping/sub-categories/sub-categories.component';
import {SubCategoryComponent} from './custom/setup/accounting-setup/subledger-mapping/sub-category/sub-category.component';
import {VendorsComponent} from './custom/occupancy/vendors/vendors.component';
import {FileroomModule} from './fileroom/fileroom.module';
import {FileroomUploaderComponent, FileroomDashboardComponent} from '@hds1/fileroom';
import {FileroomUploadButtonComponent} from './fileroom/fileroom-upload-button/fileroom-upload-button.component';
import {VendorLedgerComponent} from './custom/vendor/vendor-ledger/vendor-ledger.component';
import {VoidTransactionPopupComponent} from './custom/vendor/vendor-ledger/vendor-transaction-detail/void-transaction-popup/void-transaction-popup.component';
import {VendorAdjustmentComponent} from './custom/vendor/vendor-ledger/vendor-adjustment/vendor-adjustment.component';
import {VendorRecurringTransactionEditComponent} from './custom/vendor/vendor-ledger/vendor-recurring-transaction-edit/vendor-recurring-transaction-edit.component';
import {LandlordPropertiesComponent} from './custom/occupancy/vendors/landlord-properties/landlord-properties.component';
import {VendorOverviewComponent} from './custom/vendor/vendor-overview/vendor-overview.component';
import {VendorHeaderComponent} from './custom/vendor/vendor-header/vendor-header.component';
import {EditFundingAndDepartmentPopupComponent} from './custom/setup/housing-authority-setup/edit-funding-and-department-popup/edit-funding-and-department-popup.component';
import {EditIncomeLimitAreaComponent} from './custom/setup/housing-authority-setup/edit-income-limit-area/edit-income-limit-area.component';
import {EditRegionalRentLimitComponent} from './custom/setup/housing-authority-setup/edit-regional-rent-limit/edit-regional-rent-limit.component';
import {EditPaymentStandardsComponent} from './custom/setup/housing-authority-setup/edit-payment-standards/edit-payment-standards.component';
import {UnitSetupMonthlyChargeComponent} from './custom/setup/units/unit-setup/unit-setup-monthly-charge/unit-setup-monthly-charge.component';
import {RentcalcOptionPopupComponent} from './custom/setup/rentcalc-option-popup/rentcalc-option-popup.component';
import {VendorNotesComponent} from './custom/vendor/vendor-notes/vendor-notes.component';
import {VendorNotesCreateComponent} from './custom/vendor/vendor-notes/vendor-notes-create/vendor-notes-create.component';
import {VendorEditComponent} from './custom/vendor/vendor-edit/vendor-edit.component';
import {UnitsListComponent} from './custom/setup/units/units-list/units-list.component';
import {UnitSetupComponent} from './custom/setup/units/unit-setup/unit-setup.component';
import {UnitSetupHandlerComponent} from './custom/setup/units/unit-edit-handler/unit-setup-handler.component';
import {UtilityAllowancesComponent} from './custom/setup/utility-allowances/utility-allowances.component';
import {UtilityActionPopupComponent} from './custom/setup/utility-allowances/utility-action-popup/utility-action-popup.component';
import {PublicHousingActionComponent} from './custom/occupancy/household-actions/public-housing-action/public-housing-action.component';
import {CustomAttributesComponent} from './kanso-common/core/components/custom-attributes/custom-attributes.component';
import {PayoutsComponent} from './custom/accounting/payouts/payouts.component';
import {PayablesHistoryComponent} from './custom/accounting/payouts/payables-history/payables-history.component';
import {PayableDetailComponent} from './custom/accounting/payouts/payable-detail/payable-detail.component';
import {PostAdjustmentComponent} from './custom/accounting/payouts/post-adjustment/post-adjustment.component';
import {PayablesNotesComponent} from './custom/accounting/payouts/payables-notes/payables-notes.component';
import {PayablePostedOnComponent} from './custom/accounting/payouts/payable-detail/payable-posted-on/payable-posted-on.component';
import {PayablesWizardComponent} from './custom/accounting/payouts/payables-wizard/payables-wizard.component';
import {KansoWizardHeaderComponent} from './kanso-common/core/components/kanso-wizard-header/kanso-wizard-header.component';
import {KansoWizardContentComponent} from './kanso-common/core/components/kanso-wizard-content/kanso-wizard-content.component';
import {PayableWizardTableComponent} from './custom/accounting/payouts/payables-wizard/payable-wizard-table/payable-wizard-table.component';

import {FeatureConfigurationsListComponent} from './custom/feature-configurations/feature-configurations-list/feature-configurations-list.component';
import {IntegrationsComponent} from './custom/integrations/integrations/integrations.component';
import {MonthEndComponent} from './custom/accounting/month-end/month-end.component';
import {BuildingsListComponent} from './custom/setup/units/buildings-list/buildings-list.component';
import {BuildingsSetupComponent} from './custom/setup/units/buildings-setup/buildings-setup.component';
import {UnitUtilityOverridesComponent} from './custom/setup/units/unit-utility-overrides/unit-utility-overrides.component';
import {ShofcorpIntergrationsComponent} from './custom/occupancy/household-actions/shofcorp-intergrations/shofcorp-intergrations.component';

export function createApollo(httpLink: HttpLink) {
  const CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  const SITE_ID = sessionStorage.getItem('SITEID') || '';
  const auth = new ApolloLink((operation, forward) => {
    operation.setContext(({headers = {// Remove for prod
        'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'), 'x-site-id': SITE_ID, 'x-customer-id': CUSTOMER_ID}}) => ({
      headers: {
        ...headers,
      },
    }));
    return forward(operation);
  });

  return {
    link: from([
      auth,
      httpLink.create({
        uri: sessionStorage.getItem('VOUCHERS_SVC_GRAPHQL_ENDPOINT'),
        // Remove for prod
      }),
    ]),
    cache: new InMemoryCache(),
  };
}

const routes: Routes = [{path: 'vouchers', component: VouchersOverviewComponent}];

@NgModule({
  declarations: [AppComponent, GlobalAngular2ErrorHandlerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    LayoutModule,
    FormEntryModule,
    FormsModule,
    BrowserModule,
    RouterModule.forRoot([], {relativeLinkResolution: 'legacy'}),
    RouterModule.forRoot([], {relativeLinkResolution: 'legacy'}),
    ChartsModule,
    DataExportModule,
    SharedModule,
    VouchersModule,
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
    CoreModule,
    CustomModule,
    KansoCommonModule,
    OccupancyModule,
    FileroomModule,
  ],
  exports: [RouterModule],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [ConfigService],
      multi: true,
    },
    ParameterStoreService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initFunction,
      deps: [ParameterStoreService, SitePropertiesService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
  entryComponents: [
    AppComponent,
    VouchersAddEditrftaComponent,
    VouchersAddEditComponent,
    VouchersIssueWizardComponent,
    GeneralLedgerComponent,
    GeneralLedgerEditComponent,
    HousingAuthoritySetupComponent,
    EditFundingAndDepartmentPopupComponent,
    EditIncomeLimitAreaComponent,
    EditRegionalRentLimitComponent,
    EditPaymentStandardsComponent,
    ProgramSetupComponent,
    ProgramSetupListComponent,
    ProgramRentCalcComponent,
    AccountActivityRuleComponent,
    ProjectSetupComponent,
    ProjectSetupListComponent,
    SubledgerMappingComponent,
    SubledgerMappingEditComponent,
    SubledgerMappingEditFullComponent,
    SubCategoriesComponent,
    SubCategoryComponent,
    VendorsComponent,
    VendorLedgerComponent,
    VoidTransactionPopupComponent,
    VendorAdjustmentComponent,
    VendorRecurringTransactionEditComponent,
    VendorOverviewComponent,
    LandlordPropertiesComponent,
    VendorHeaderComponent,
    UnitSetupMonthlyChargeComponent,
    RentcalcOptionPopupComponent,
    EditOfficeLocationComponent,
    VendorNotesComponent,
    VendorNotesCreateComponent,
    VendorEditComponent,
    UnitsListComponent,
    UnitSetupComponent,
    UnitSetupHandlerComponent,
    UtilityAllowancesComponent,
    UtilityActionPopupComponent,
    PublicHousingActionComponent,
    ShofcorpIntergrationsComponent,
    CustomAttributesComponent,
    PayoutsComponent,
    PayablesHistoryComponent,
    PayableDetailComponent,
    PostAdjustmentComponent,
    PayablesNotesComponent,
    PayablePostedOnComponent,
    PayablesWizardComponent,
    PayableWizardTableComponent,
    KansoWizardHeaderComponent,
    KansoWizardContentComponent,
    FeatureConfigurationsListComponent,
    FileroomUploaderComponent,
    FileroomDashboardComponent,
    FileroomUploadButtonComponent,
    IntegrationsComponent,
    MonthEndComponent,
    FileroomUploaderComponent,
    FileroomDashboardComponent,
    FileroomUploadButtonComponent,
    FileroomUploaderComponent,
    IntegrationsComponent,
    MonthEndComponent,
    BuildingsListComponent,
    BuildingsSetupComponent,
    UnitUtilityOverridesComponent,
  ],
})
export class AppModule {
  customerId: string;
  siteId: string;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  ngDoBootstrap(): void {} // eslint-disable-line @typescript-eslint/no-empty-function
}

/**
 * Initializer for all parameter store variables that front-load information into session storage as is necessary
 *
 * Initializer for some site property variables that front-load information into session storage as is necessary
 *
 * @param parameterStore Parameter Store Service
 * @returns {void}
 */

export function loadConfig(configService: ConfigService) {
  return () => configService.loadConfig();
}

export function initFunction(parameterStore: ParameterStoreService, siteProperties: SitePropertiesService) {
  return () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        const data = await siteProperties.getSiteProperties().toPromise();
        Object.keys(data)
          .filter(key => key.includes('site') || key.includes('customer'))
          .forEach(entry => sessionStorage.setItem(entry.trim().toUpperCase(), data[entry]));

        resolve(); // Resolve the promise when the asynchronous operations are complete
      } catch (error) {
        reject(error); // Reject the promise if an error occurs
      }
    });
  };
}

//}
