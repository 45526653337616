import {Component, ViewChild} from '@angular/core';
import {
  CreateSageDataCommand,
  EncryptedSageData,
  IntegrationsService,
  SageClientData,
  TenantPortalConfigurationInput,
  UpdateSageDataCommand,
} from 'src/app/shared/services/integrations-service';
import lodash from 'lodash';
import {UserService} from 'src/app/shared/services';
import {User} from 'src/app/core/service/core-models';
import {CoreService} from 'src/app/core/service/core.service';
import {EncryptionService} from '../../accounting/service/encryption.service';
import {FeatureConfigurationsService} from 'src/app/shared/services/feature-configurations.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent {
  @ViewChild('editIntegrationsForm') editIntegrationsForm;
  isBusy = false;
  busyText = '';
  error = false;
  isStripe = true;
  loadingStripe = true;
  loadingSageClientData = true;
  sageIntacctConfigurationsPanel = true;
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  tenantPortalConfigurationPanel = true;
  userEmail: string;
  showSageInt = false;
  showStripeInt = false;
  showAmerindFields = false;
  tenantPortalConfiguration: TenantPortalConfigurationInput = {
    id: '',
    bankPortalURL: '',
    paymentServicePrivateKey: '',
    paymentServicePublicKey: '',
    siteId: '',
  };
  tenantPortalConfigurationCopy: TenantPortalConfigurationInput;
  sageClientData: SageClientData = {
    id: '',
    endpointUrl: '',
    companyId: '',
    dataKey: '',
    dataVector: '',
  };
  sageClientDataCopy: SageClientData;
  sageEncryptedData: EncryptedSageData = {
    id: '',
    userId: '',
    userPassword: '',
    siteId: '',
    customerId: '',
  };
  sageEncryptedDataCopy: EncryptedSageData;
  createSageDataCommand: CreateSageDataCommand = {
    endpointUrl: '',
    companyId: '',
    dataKey: '',
    dataVector: '',
    createdBy: '',
    userId: '',
    userPassword: '',
    customerId: '',
    siteId: '',
  };
  updateSageDataCommand: UpdateSageDataCommand = {
    id: '',
    endpointUrl: '',
    companyId: '',
    dataKey: '',
    dataVector: '',
    modifiedBy: '',
    userId: '',
    userPassword: '',
    customerId: '',
    siteId: '',
  };
  sageDataKey: string;
  sageDataVector: string;

  constructor(
    private integrationsService: IntegrationsService,
    public userService: UserService,
    private coreService: CoreService,
    private encryptionService: EncryptionService,
    private featureConfigurationsService: FeatureConfigurationsService
  ) {
    this.setBusy('Loading Integrations Data...');
    this.userService.getUser().subscribe(
      (user: User) => {
        //this.hasPermission = this.userService.permissionExists(user.permissions, this.achPermission);
        this.userEmail = user.email;
      },
      error => {
        console.error(error);
        this.coreService.displayError('There was error when retrieving current user information');
      }
    );
    this.getToggleInformation();

    this.integrationsService.getStripeData().subscribe(
      (result: TenantPortalConfigurationInput) => {
        if (result) {
          this.tenantPortalConfiguration = result;
          this.tenantPortalConfigurationCopy = lodash.cloneDeep(this.tenantPortalConfiguration);
          if (this.tenantPortalConfiguration.bankPortalURL) {
            this.isStripe = false;
          }
        } else {
          this.tenantPortalConfigurationCopy = lodash.cloneDeep(this.tenantPortalConfiguration);
        }
        this.integrationsService.getSageClientData().subscribe(sageKansoData => {
          if (sageKansoData) {
            this.sageClientData = sageKansoData.node;
            this.sageClientDataCopy = lodash.cloneDeep(this.sageClientData);
            this.integrationsService.getEncryptedSageClientData(sageKansoData.node.id).subscribe(async sageKimonoData => {
              if (sageKimonoData) {
                const decryptionObject = [sageKimonoData.userId, sageKimonoData.userPassword];
                const decryptedArray = await this.encryptionService.decryptArrayData(
                  decryptionObject,
                  sageKansoData.node.dataKey,
                  sageKansoData.node.dataVector
                );
                if (decryptedArray.plainTextArray[0]) {
                  this.sageEncryptedData.userId = decryptedArray.plainTextArray[0];
                  this.sageEncryptedData.userPassword = decryptedArray.plainTextArray[1];
                  this.sageEncryptedDataCopy = lodash.cloneDeep(this.sageEncryptedData);
                  this.sageEncryptedData.userPassword = '*************';
                  this.isBusy = false;
                } else {
                  console.error('Error decrypting', decryptedArray);
                  this.coreService.displayError('An unexpected error occurred decrypting data.');
                  this.isBusy = false;
                }
              }
            });
          } else {
            this.sageEncryptedDataCopy = lodash.cloneDeep(this.sageEncryptedData);
            this.sageClientDataCopy = lodash.cloneDeep(this.sageClientData);
            this.isBusy = false;
          }
        });
      },
      error => {
        console.error('Error getting data', error);
        this.coreService.displayError('An unexpected error occurred getting data.');
        this.isBusy = false;
      }
    );
  }

  getToggleInformation() {
    this.featureConfigurationsService.getFeatureConfigurations().subscribe(result => {
      this.featureConfigurationsService.getConfigurationsValues(this.SITE_ID).subscribe(configValues => {
        for (const featureConfig of result[0]) {
          const selectedValue = configValues[0].find(config => config.featureConfigurationId === featureConfig.id);
          if (selectedValue) {
            switch (true) {
              case featureConfig.subdomain == 'sageIntacct' && selectedValue.booleanValue == true: {
                this.showSageInt = true;
                break;
              }
              case featureConfig.subdomain == 'stripeInt' && selectedValue.booleanValue == true: {
                this.showStripeInt = true;
                break;
              }
              case featureConfig.subdomain == 'amerindUnitFields' && selectedValue.booleanValue == true: {
                this.showAmerindFields = true;
                break;
              }
            }
          }
        }
      });
    });
  }
  setBusy(busyText: string) {
    this.isBusy = true;
    this.busyText = busyText;
  }

  cancelIntegrationsForm() {
    this.tenantPortalConfiguration = lodash.cloneDeep(this.tenantPortalConfigurationCopy);
    this.sageEncryptedData = lodash.cloneDeep(this.sageEncryptedDataCopy);
    this.sageClientData = lodash.cloneDeep(this.sageClientDataCopy);
    this.editIntegrationsForm.form.markAsPristine();
  }

  saveIntegrationsForm() {
    this.updateIntegrations();
    this.editIntegrationsForm.form.markAsPristine();
  }

  async createEncryptSageData() {
    try {
      const encryptionObject = [this.sageEncryptedData.userId, this.sageEncryptedData.userPassword];
      const result = await this.encryptionService.encryptArrayData(encryptionObject);
      if (result) {
        const {encryptedData, dataKey, dataVector} = result;
        this.createSageDataCommand = {
          endpointUrl: this.sageClientData.endpointUrl,
          companyId: this.sageClientData.companyId,
          dataKey: dataKey,
          dataVector: dataVector,
          createdBy: this.userEmail,
          userId: encryptedData[0],
          userPassword: encryptedData[1],
          customerId: sessionStorage.getItem('CUSTOMERID'),
          siteId: sessionStorage.getItem('SITEID'),
        };
        this.integrationsService.createSageData(this.createSageDataCommand).subscribe((result: any) => {
          if (result.data.createSageClientData.status == 'SUCCESS') {
            this.sageEncryptedDataCopy = lodash.cloneDeep(this.sageEncryptedData);
            this.sageClientDataCopy = lodash.cloneDeep(this.sageClientData);
            this.isBusy = false;
          } else {
            this.coreService.displayError('An unexpected error occurred saving Sage Data.');
            this.cancelIntegrationsForm();
            this.isBusy = false;
          }
        });
      }
    } catch (error) {
      this.coreService.displayError('An unexpected error occurred creating Sage data.');
      console.error('Failed to encrypt data:', error);
      this.cancelIntegrationsForm();
      this.isBusy = false;
      return;
    }
  }

  async updateSageClientData() {
    let updateCommand = {};
    try {
      if (this.sageEncryptedData.userId != this.sageEncryptedDataCopy.userId || this.sageEncryptedData.userPassword != '*************') {
        const encryptionObject = [this.sageEncryptedData.userId, this.sageEncryptedData.userPassword];
        const result = await this.encryptionService.encryptArrayData(encryptionObject);
        if (result) {
          const {encryptedData, dataKey, dataVector} = result;
          this.updateSageDataCommand = {
            id: this.sageClientData.id,
            endpointUrl: this.sageClientData.endpointUrl,
            companyId: this.sageClientData.companyId,
            dataKey: dataKey,
            dataVector: dataVector,
            modifiedBy: this.userEmail,
            userId: encryptedData[0],
            userPassword: encryptedData[1],
            customerId: sessionStorage.getItem('CUSTOMERID'),
            siteId: sessionStorage.getItem('SITEID'),
          };
        } else {
          console.error('Error with encryption service', result);
          this.coreService.displayError('There was error encrypting data');
          this.cancelIntegrationsForm();
          this.isBusy = false;
        }
        updateCommand = this.updateSageDataCommand;
      } else {
        updateCommand = {
          id: this.sageClientData.id,
          endpointUrl: this.sageClientData.endpointUrl,
          companyId: this.sageClientData.companyId,
          modifiedBy: this.userEmail,
          customerId: sessionStorage.getItem('CUSTOMERID'),
          siteId: sessionStorage.getItem('SITEID'),
        };
      }
      this.integrationsService.updateSageData(updateCommand).subscribe((result: any) => {
        if (result.data.updateSageClientData.status === 'SUCCESS') {
          this.sageEncryptedDataCopy = lodash.cloneDeep(this.sageEncryptedData);
          this.sageClientDataCopy = lodash.cloneDeep(this.sageClientData);
          this.isBusy = false;
        } else {
          this.coreService.displayError('There was error saving updated Sage Client Data');
          this.cancelIntegrationsForm();
          this.isBusy = false;
        }
      });
    } catch (error) {
      this.coreService.displayError('An unexpected error occurred while encrypting the data.');
      console.error('Failed to encrypt data:', error);
      this.isBusy = false;
    }
  }

  saveTenantPortalData() {
    if (this.isStripe) {
      this.tenantPortalConfiguration.bankPortalURL = null;
    } else {
      this.tenantPortalConfiguration.paymentServicePrivateKey = null;
      this.tenantPortalConfiguration.paymentServicePublicKey = null;
    }
    this.integrationsService
      .updateTenantPortalConfiguration({
        siteId: this.SITE_ID,
        paymentServicePrivateKey: this.tenantPortalConfiguration.paymentServicePrivateKey,
        paymentServicePublicKey: this.tenantPortalConfiguration.paymentServicePublicKey,
        bankPortalURL: this.tenantPortalConfiguration.bankPortalURL,
      })
      .subscribe(
        (result: any) => {
          if (result.data.setPaymentProcessingKeys.isSuccessful) {
            this.isBusy = false;
          } else {
            this.coreService.displayError('An unexpected error occurred saving updated Tenant Portal Config.');
            this.cancelIntegrationsForm();
            this.isBusy = false;
          }
        },
        error => {
          this.coreService.displayError('An unexpected error occurred updating Tenant Portal Config.');
          console.error('Error saving Tenant Portal Configurations', error);
          this.cancelIntegrationsForm();
          this.isBusy = false;
        }
      );
  }

  updateIntegrations() {
    this.setBusy('Encrypting and Saving Integration Data...');
    try {
      if (
        this.tenantPortalConfiguration.paymentServicePrivateKey != this.tenantPortalConfigurationCopy.paymentServicePrivateKey ||
        this.tenantPortalConfiguration.paymentServicePublicKey != this.tenantPortalConfigurationCopy.paymentServicePublicKey ||
        this.tenantPortalConfiguration.bankPortalURL != this.tenantPortalConfigurationCopy.bankPortalURL
      ) {
        this.saveTenantPortalData();
      }
      if (
        this.sageClientData.id === '' &&
        (this.sageClientData.endpointUrl != this.sageClientDataCopy.endpointUrl ||
          this.sageClientData.companyId != this.sageClientDataCopy.companyId ||
          this.sageEncryptedData.userId != this.sageEncryptedDataCopy.userId ||
          this.sageEncryptedData.userPassword != this.sageEncryptedDataCopy.userPassword)
      ) {
        this.createEncryptSageData();
      }
      if (
        this.sageClientData.id &&
        (this.sageClientData.endpointUrl != this.sageClientDataCopy.endpointUrl ||
          this.sageClientData.companyId != this.sageClientDataCopy.companyId ||
          this.sageEncryptedData.userId != this.sageEncryptedDataCopy.userId ||
          this.sageEncryptedData.userPassword != '*************')
      ) {
        this.updateSageClientData();
      }
    } catch {
      this.coreService.displayError('An unexpected error occurred updating integrations.');
      console.error('Error updating integrations');
      this.isBusy = false;
    }
  }
}
